import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image";

import { spin, rotate } from "../components/layout.module.css"

function RotateFace(){
    console.log(document.getElementsByClassName('face')[0]);
    document.getElementsByClassName('face')[0].animate(
        [
            {transform: 'rotate(0deg)'},
            { transform: 'rotate(360deg)' }
        ],
        {
            duration: 1000,
            iterations: 1,
            composite: "add",
        }
    );
    console.log("done");
    // document.getElementsByClassName('face')[0].classList.add('spin');
}

export function MyFace() {
    return <a id="spinner" onClick={RotateFace}>
            <StaticImage 
            src="../images/icon.png" 
            alt="*Insert my face here*" 
            placeholder="blurred"
            layout="constrained"
            height={50}
            className="face"
            style={{ float: "left" }}
            />
        </a>
}