// Step 1: Import React
import * as React from 'react'

import { Layout } from 'antd';

const { Footer } = Layout;



export function MyFooter(){
  return (
    <main>
      <Layout className="layout">
        <Footer style={{ textAlign: 'center', fontStyle: "italic", position: 'fixed', bottom: "0", zIndex: 1, width: '100%' }}>Made with <span role="img" aria-label="Heart emoji">❤️</span>... or was it?</Footer>
  </Layout>
</main>
  )
}