// Step 1: Import React
import * as React from 'react'

import { Link } from 'gatsby';
import { Layout, Menu, Breadcrumb, Divider, Typography } from 'antd';

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';

import {HeaderTitle} from '../components/layout.module.css';
import {MyFace} from './MyFace.js';

const { Header } = Layout;
const { Title, Text } = Typography



export function MyHeader(){
  return (
    <main>
      <Layout className="layout">
        <Header style={{ position: 'sticky', zIndex: 1, width: '100%'}}>
          <MyFace style={{ height: '75%' }}></MyFace>
          <Divider type="vertical"></Divider>
          <Link to="/">
            <Title className={ HeaderTitle } style={{ color: "#fff" }}>Dakota Sanders</Title>
            <Text italic="true" style={{ color: "#fff" }}>Or Cody, take your pick</Text>
          </Link>
          <Menu theme="dark" mode="horizontal" style={{ position: 'relative', float: "right" }}>
            <Menu.Item key="1">
              <Link to="/about">
                About
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">
                Important Link
              </a>
            </Menu.Item>
            <Menu.Item key="3">
              nav 3
            </Menu.Item>
          </Menu>
        </Header>
  </Layout>
</main>
  )
}